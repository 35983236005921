var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import FormDropdownSelect from "app/components/common/form-dropdown-select";
import NoResults from "app/components/common/no-results";
import OrchModal from "app/components/common/orch-modal";
import * as PageHeader from "app/components/common/page-header";
import { Portlet, PortletBody, PortletHeader } from "app/components/common/portlet";
import { SkeletonFakeTableCol, SkeletonFakeTableRow, SkeletonLoaderFakeBox } from "app/components/common/skeleton-loaders";
import { SubtitleButton } from "app/components/common/subtitle";
import OrchestryService from "app/services/orchestry";
import * as TenantListStore from "app/store/tenants/list";
import axios from "axios";
import moment from "moment";
import * as React from "react";
import { Col, Form, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import "./orchestry-admin-job-browser.scss";
var OrchestryAdminJobBrowser = React.memo(function () {
    var _a, _b;
    var dispatch = useDispatch();
    var tenants = useSelector(function (state) { return state.tenantListState.items; });
    var _c = React.useState("US"), selectedRegion = _c[0], setSelectedRegion = _c[1];
    var _d = React.useState(null), selectedTenant = _d[0], setSelectedTenant = _d[1];
    var _e = React.useState(null), selectedJobType = _e[0], setSelectedJobType = _e[1];
    var _f = React.useState(null), selectedStatus = _f[0], setSelectedStatus = _f[1];
    var _g = React.useState(null), jobs = _g[0], setJobs = _g[1];
    var _h = React.useState(window.innerHeight), scollAreaHeight = _h[0], setScrollAreaHeight = _h[1];
    var _j = React.useState(null), selectJobId = _j[0], setSelectedJobId = _j[1];
    var iframeRef = React.useRef();
    var _k = React.useState(false), modalOpen = _k[0], setModalOpen = _k[1];
    var _l = React.useState(false), loadingData = _l[0], setLoadingData = _l[1];
    var _m = React.useState(), activeRegions = _m[0], setActiveRegions = _m[1];
    var _o = React.useState([]), methods = _o[0], setMethods = _o[1];
    var _p = React.useState(200), topQuery = _p[0], setTopQuery = _p[1];
    React.useEffect(function () {
        dispatch(TenantListStore.Load());
        getJobs();
    }, []);
    React.useEffect(function () {
        OrchestryService.GetActiveRegionsAsDropdownOptions().then(function (regions) {
            setActiveRegions(regions);
        });
    }, []);
    React.useEffect(function () {
        getJobs();
    }, [selectedRegion, selectedTenant, selectedJobType, topQuery]);
    var getJobs = function (top, update) {
        if (top === void 0) { top = null; }
        if (update === void 0) { update = false; }
        var axiosConfig = {};
        axiosConfig.method = "GET";
        axiosConfig.url = "/api/hangfire/GetJobs?region=" + selectedRegion + "&tenantGuid=" + selectedTenant + "&type=" + selectedJobType + (topQuery !== null ? "&top=" + topQuery : "");
        axiosConfig.responseType = "json";
        // if (top === null) {
        if (update === false) {
            setJobs(null);
        }
        setLoadingData(true);
        axios(axiosConfig)
            .then(function (response) {
            var tempJobs = response.data;
            var methods = [];
            tempJobs.forEach(function (tj) {
                if (tj.Arguments !== undefined && tj.Arguments !== null) {
                    tj.ArgumentsArray = JSON.parse(tj.Arguments);
                }
                if (tj.InvocationData !== undefined && tj.InvocationData !== null) {
                    tj.InvocationDataObject = JSON.parse(tj.InvocationData);
                    if (tj.InvocationDataObject !== undefined &&
                        tj.InvocationDataObject !== null &&
                        tj.InvocationDataObject.ParameterTypes !== undefined &&
                        tj.InvocationDataObject.ParameterTypes !== null) {
                        tj.InvocationDataObject.ParameterTypesObject = JSON.parse(tj.InvocationDataObject.ParameterTypes);
                    }
                }
                if (tj.InvocationDataObject !== undefined && tj.InvocationDataObject !== null && tj.InvocationDataObject.Method !== null) {
                    if (!methods.some(function (m) { return m === tj.InvocationDataObject.Method; })) {
                        methods = __spreadArray(__spreadArray([], methods, true), [tj.InvocationDataObject.Method], false);
                    }
                }
                if (tj.StateEnqueuedData !== undefined && tj.StateEnqueuedData !== null) {
                    var parsedData = JSON.parse(tj.StateEnqueuedData);
                    tj.Queue = parsedData.Queue;
                }
                if (tj.StateProcessingData !== undefined && tj.StateProcessingData !== null) {
                    var parsedData = JSON.parse(tj.StateProcessingData);
                    tj.Server = parsedData.ServerId;
                }
            });
            // console.log("methods", methods);
            // console.log("jobs", tempJobs);
            // if(jobs !== null){
            //    let newJobs = [...jobs.map((j) => {
            //       if(tempJobs.some(tj => tj.Id === j.Id)){
            //          return tempJobs.find(tj => tj.Id === j.Id) as HfJob;
            //       } else {
            //          return j;
            //       }
            //    }), ...tempJobs.filter(tj => !jobs.some(j => j.Id === tj.Id))];
            //    newJobs.sort(function (a, b) {
            //       if (a.Id > b.Id) { return -1; }
            //       if (a.Id < b.Id) { return 1; }
            //       return 0;
            //    })
            //    setJobs(newJobs);
            // } else {
            setJobs(tempJobs);
            // }
            //response.data
            setLoadingData(false);
        })
            .catch(function (response) {
            //response
        });
    };
    var updateScrollAreaHeight = function () {
        var newHeight = window.innerHeight - 300;
        if (scollAreaHeight !== newHeight) {
            // console.log("setScrollAreaHeight", (window.innerHeight));
            // console.log("setScrollAreaHeightWithoutNav", (newHeight));
            // setScrollAreaHeight(window.innerHeight);
            setScrollAreaHeight(newHeight);
        }
    };
    React.useEffect(function () {
        updateScrollAreaHeight();
        window.addEventListener("resize", updateScrollAreaHeight);
        return function () {
            window.removeEventListener("resize", updateScrollAreaHeight);
        };
    }, []);
    React.useEffect(function () {
        updateScrollAreaHeight();
        updateMethods();
    }, [jobs]);
    var updateMethods = function () {
        if (jobs !== null) {
            var exisitingMethods_1 = __spreadArray([], methods, true);
            jobs.forEach(function (job) {
                if (!exisitingMethods_1.some(function (j) { return j === job.InvocationDataObject.Method; })) {
                    exisitingMethods_1.push(job.InvocationDataObject.Method);
                }
            });
            setMethods(exisitingMethods_1);
        }
    };
    var JobTypes = [
        {
            Method: "HangfireProcessAssignPoliciesJob",
            JobNameString: "Archival Policies > Bulk Assignments"
        },
        {
            Method: "StartProvisioningProcessQueueIfNotRunning",
            JobNameString: "Provisioning > Start Process Queue"
        },
        {
            Method: "StartArchivalProcessQueueIfNotRunning",
            JobNameString: "Archival Policies > Start Process Queue"
        },
        {
            Method: "HangfireTenantDailyETLJob",
            JobNameString: "ETL > Tenant Daily Job"
        },
        {
            Method: "HangfireTenantSingleETLJob",
            JobNameString: "ETL > Single Workpspace Job"
        },
        {
            Method: "HangfireProcessAllDailyETLJobs",
            JobNameString: "ETL > Process Queue > Nightly Job"
        },
        {
            Method: "ProcessAllDailyETLJobs",
            JobNameString: "ETL > Process Queue > Specific Day"
        },
        {
            Method: "HangfireProcessAllDailyDataAnalysisJobs",
            JobNameString: "Data Analysis > Process Queue > Nightly Job"
        },
        {
            Method: "HangfireTenantDailyDataAnalysisJob",
            JobNameString: "Data Analysis > Tenant Daily Job"
        },
        {
            Method: "UpdateDirectoryItems_JobStart",
            JobNameString: "Maintenance > Update Directory For Tenant"
        },
        {
            Method: "ApplyFileTemplateToSites",
            JobNameString: "Tools > Apply Template to Sites"
        },
        {
            Method: "ProcessNotificationsWithHangfire",
            JobNameString: "Webhooks > Add Incoming Calls To Queue"
        },
        {
            Method: "ProcessSiteUpdatesWithHangfire",
            JobNameString: "Webhooks > Process Incoming Workspace Update Queue"
        },
        {
            Method: "NightlyWebhookMaintenance",
            JobNameString: "Webhooks > Nightly Job Maintenance"
        },
        {
            Method: "MaintainTenantWebhooks",
            JobNameString: "Webhooks > Maintain Webhooks"
        },
        {
            Method: "ProcessAllInstallSteps_Start",
            JobNameString: "Install"
        },
        {
            Method: "HangfireArchivalPolicyJob",
            JobNameString: "Archival Policies > Process Workspace"
        },
        {
            Method: "HangfireArchivalProcessQueueJob",
            JobNameString: "Archival Policies > Process Queues"
        },
        {
            Method: "DailyPolicyEnforcement",
            JobNameString: "Policy Enforcement > Daily (old - pre august 2023 update) > Tenant"
        },
        {
            Method: "HangfireDailyPolicyEnforcement",
            JobNameString: "Policy Enforcement > Daily > Tenant"
        },
        {
            Method: "HangfirePolicyEnforcementOnSpecificWorkspaces",
            JobNameString: "Policy Enforcement > Workspaces > Tenant"
        },
        {
            Method: "HangfireStartJob",
            JobNameString: "Provisioning > Provision Request"
        },
        {
            Method: "FixQueuedJobsForServersNotRunning",
            JobNameString: "Maintenance > Fix Queued Jobs"
        },
        {
            Method: "CreateTestTeams",
            JobNameString: "Maintenance > Create Test Teams"
        },
        {
            Method: "DeleteDirectoryItemsArchived",
            JobNameString: "Maintenance > Fix Directory Items"
        },
        {
            Method: "HangfireArchivalProcessQueueJobAllTenants",
            JobNameString: "Archival Policies > Process Queues > All Tenants"
        },
        {
            Method: "HangfireArchivalProcessQueueJobSpecificTenant",
            JobNameString: "Archival Policies > Process Queues > Specific Tenant"
        },
        {
            Method: "HangfireNightlyDataDeletionPolicy",
            JobNameString: "Maintenance > SOC 2 Deletion Enforcement"
        },
        {
            Method: "MaintainTenantWebhooks",
            JobNameString: "Maintenance > Refresh Webhooks"
        },
        {
            Method: "HangfireProcessRequestsQueue",
            JobNameString: "Provisioning > Start Process Queue"
        },
        {
            Method: "RefreshAppSecrets",
            JobNameString: "Maintenance > Refresh App Secrets"
        },
        {
            Method: "RunScriptRunnerAutomatic",
            JobNameString: "Maintenance > Run Startup Scripts"
        },
        {
            Method: "RunTenantDbStartup",
            JobNameString: "Maintenance > Update Tenant Dbs Schemas"
        }
    ];
    var GetJobTypes = function () {
        var JobsArray = [];
        if (methods !== null) {
            methods.forEach(function (method) {
                //if (!JobsArray.some((j) => j.value === job.InvocationDataObject.Method)) {
                var JobNameString = "";
                var foundJobType = JobTypes.find(function (jt) { return jt.Method === method; });
                if (foundJobType !== null && foundJobType !== undefined) {
                    JobNameString = foundJobType.JobNameString;
                }
                JobsArray = __spreadArray(__spreadArray([], JobsArray, true), [
                    {
                        id: "" + (JobsArray.length + 1),
                        title: JobNameString !== null && JobNameString !== "" ? JobNameString : "M - " + method,
                        value: method
                    }
                ], false);
                // }
            });
        }
        JobTypes.forEach(function (jt) {
            if (JobsArray.some(function (ja) { return ja.value === jt.Method; }) === false) {
                JobsArray = __spreadArray(__spreadArray([], JobsArray, true), [
                    {
                        id: "" + (JobsArray.length + 1),
                        title: jt.JobNameString !== null && jt.JobNameString !== "" ? jt.JobNameString : "M - " + jt.Method,
                        value: jt.Method
                    }
                ], false);
            }
        });
        JobsArray.sort(function (a, b) {
            if (a.title < b.title) {
                return -1;
            }
            if (a.title > b.title) {
                return 1;
            }
            return 0;
        });
        return JobsArray;
    };
    var GetJobName = function (job) {
        var JobNameString = "";
        if (job.InvocationDataObject.Method === "HangfireTenantDailyETLJob") {
            JobNameString = "ETL > Tenant > {2} > {1}";
        }
        if (job.InvocationDataObject.Method === "HangfireTenantDailyDataAnalysisJob") {
            JobNameString = "Data Analysis > Tenant > {2} > {1}";
        }
        if (job.InvocationDataObject.Method === "StartProvisioningProcessQueueIfNotRunning") {
            JobNameString = "Provisioning > Start Process Queue";
        }
        if (job.InvocationDataObject.Method === "StartArchivalProcessQueueIfNotRunning") {
            JobNameString = "Archival Policies > Start Process Queue";
        }
        if (job.InvocationDataObject.Method === "HangfireTenantSingleETLJob") {
            JobNameString = "ETL > Single Workspace > {2} > {0}";
        }
        if (job.InvocationDataObject.Method === "HangfireProcessAllDailyETLJobs") {
            JobNameString = "ETL > Process Queue";
        }
        if (job.InvocationDataObject.Method === "HangfireProcessAllDailyDataAnalysisJobs") {
            JobNameString = "Data Analysis > Process Queue";
        }
        if (job.InvocationDataObject.Method === "ProcessAllDailyETLJobs") {
            JobNameString = "ETL > Process Queue > {0}";
        }
        if (job.InvocationDataObject.Method === "UpdateDirectoryItems_JobStart") {
            JobNameString = "Update Directory > {0}";
        }
        if (job.InvocationDataObject.Method === "ApplyFileTemplateToSites") {
            JobNameString = "Tools > Apply Template to Sites > {0}";
        }
        if (job.InvocationDataObject.Method === "ProcessNotificationsWithHangfire") {
            JobNameString = "Webhooks > Add Incoming Calls To Queue";
        }
        if (job.InvocationDataObject.Method === "ProcessSiteUpdatesWithHangfire") {
            JobNameString = "Webhooks > Process Incoming Workspace Update Queue";
        }
        if (job.InvocationDataObject.Method === "NightlyWebhookMaintenance") {
            JobNameString = "Webhooks > Nightly Job Maintenance";
        }
        if (job.InvocationDataObject.Method === "MaintainTenantsGroupWebhook") {
            JobNameString = "Webhooks > Maintain Webhooks";
        }
        if (job.InvocationDataObject.Method === "ProcessAllInstallSteps_Start") {
            JobNameString = "Install > {0}";
        }
        if (job.InvocationDataObject.Method === "HangfireArchivalPolicyJob") {
            JobNameString = "Policy Triggered > Archival > {1} > SiteId:{2}";
        }
        if (job.InvocationDataObject.Method === "HangfireArchivalProcessQueueJob") {
            JobNameString = "Archival Policies > Process Queues";
        }
        if (job.InvocationDataObject.Method === "DailyPolicyEnforcement") {
            JobNameString = "Policy Enforcement > Daily (old job - pre aug 2023)> {0}";
        }
        if (job.InvocationDataObject.Method === "HangfireDailyPolicyEnforcement") {
            JobNameString = "Policy Enforcement > Daily > {1}";
        }
        if (job.InvocationDataObject.Method === "HangfirePolicyEnforcementOnSpecificWorkspaces") {
            JobNameString = "Policy Enforcement > Workspaces > {1}";
        }
        if (job.InvocationDataObject.Method === "HangfireStartJob") {
            JobNameString = "Provisioning > Provision Request > {1}";
        }
        if (job.InvocationDataObject.Method === "HangfireProcessRequestsQueue") {
            JobNameString = "Provisioning > Process Queue";
        }
        if (job.InvocationDataObject.Method === "HangfireProcessAssignPoliciesJob") {
            JobNameString = "Policy Assignments > {1} > {2}";
        }
        if (job.InvocationDataObject.Method === "HangfireArchivalProcessQueueJobAllTenants") {
            JobNameString = "Archival Policies > Process Queues > All Tenants";
        }
        if (job.InvocationDataObject.Method === "HangfireArchivalProcessQueueJobSpecificTenant") {
            JobNameString = "Archival Policies > Process Queues > Specific Tenant > {0}";
        }
        if (job.InvocationDataObject.Method === "FixQueuedJobsForServersNotRunning") {
            JobNameString = "Maintenance > Fix Hangfire Queues";
        }
        if (job.InvocationDataObject.Method === "HangfireNightlyDataDeletionPolicy") {
            JobNameString = "Maintenance > SOC 2 Deletion Enforcement";
        }
        if (job.InvocationDataObject.Method === "CreateTestTeams") {
            JobNameString = "Maintenance > Create Test Teams";
        }
        if (job.InvocationDataObject.Method === "RefreshAppSecrets") {
            JobNameString = "Maintenance > Refresh App Secrets";
        }
        if (job.InvocationDataObject.Method === "RunScriptRunnerAutomatic") {
            JobNameString = "Maintenance > Run Startup Scripts";
        }
        if (job.InvocationDataObject.Method === "RunTenantDbStartup") {
            JobNameString = "Maintenance > Update Tenant Dbs Schemas";
        }
        if (job.InvocationDataObject.Method === "MaintainTenantWebhooks") {
            JobNameString = "Maintenance > Maintain Tenant Webhooks";
        }
        if (job.InvocationDataObject.Method === "") {
            JobNameString = "";
        }
        if (job.InvocationDataObject.Method === "") {
            JobNameString = "";
        }
        return JobNameString === ""
            ? "~~|" + job.InvocationDataObject.Method
            : JobNameString.replace("{0}", job.ArgumentsArray[0])
                .replace("{1}", job.ArgumentsArray[1])
                .replace("{2}", job.ArgumentsArray[2])
                .replace("T00:00:00.0000000", "")
                .replace(/"/g, "");
    };
    var GetJobTenantName = function (job) {
        var _a, _b, _c, _d, _e, _f, _g;
        var TenantName = "";
        if (job.InvocationDataObject.Method === "HangfireTenantDailyETLJob") {
            TenantName = job.ArgumentsArray[1];
        }
        if (job.InvocationDataObject.Method === "HangfireTenantSingleETLJob") {
            TenantName = (_a = tenants.find(function (t) { return t.GUID === job.ArgumentsArray[0].replace(/"/g, ""); })) === null || _a === void 0 ? void 0 : _a.DisplayName;
        }
        if (job.InvocationDataObject.Method === "HangfireTenantDailyDataAnalysisJob") {
            TenantName = job.ArgumentsArray[1];
        }
        if (job.InvocationDataObject.Method === "StartProvisioningProcessQueueIfNotRunning") {
            TenantName = job.ArgumentsArray[0] === null || job.ArgumentsArray[0] === "null" ? "All" : (_b = tenants.find(function (t) { return t.GUID === job.ArgumentsArray[0].replace(/"/g, ""); })) === null || _b === void 0 ? void 0 : _b.DisplayName;
        }
        if (job.InvocationDataObject.Method === "StartArchivalProcessQueueIfNotRunning") {
            TenantName = job.ArgumentsArray[0] === null || job.ArgumentsArray[0] === "null" ? "All" : (_c = tenants.find(function (t) { return t.GUID === job.ArgumentsArray[0].replace(/"/g, ""); })) === null || _c === void 0 ? void 0 : _c.DisplayName;
        }
        if (job.InvocationDataObject.Method === "HangfireProcessAllDailyETLJobs") {
            TenantName = "All";
        }
        if (job.InvocationDataObject.Method === "HangfireProcessAllDailyDataAnalysisJobs") {
            TenantName = "All";
        }
        if (job.InvocationDataObject.Method === "ProcessAllDailyETLJobs") {
            TenantName = "All";
        }
        if (job.InvocationDataObject.Method === "UpdateDirectoryItems_JobStart") {
            TenantName = job.ArgumentsArray[0];
        }
        if (job.InvocationDataObject.Method === "ApplyFileTemplateToSites") {
            TenantName = job.ArgumentsArray[0];
        }
        if (job.InvocationDataObject.Method === "ProcessNotificationsWithHangfire") {
            TenantName = "All";
        }
        if (job.InvocationDataObject.Method === "ProcessSiteUpdatesWithHangfire") {
            TenantName = "All";
        }
        if (job.InvocationDataObject.Method === "NightlyWebhookMaintenance") {
            TenantName = "All";
        }
        if (job.InvocationDataObject.Method === "MaintainTenantsGroupWebhook") {
            TenantName = "All";
        }
        if (job.InvocationDataObject.Method === "ProcessAllInstallSteps_Start") {
            TenantName = job.ArgumentsArray[0];
        }
        if (job.InvocationDataObject.Method === "HangfireArchivalPolicyJob") {
            TenantName = job.ArgumentsArray[1];
        }
        if (job.InvocationDataObject.Method === "HangfireArchivalProcessQueueJob") {
            TenantName = job.ArgumentsArray[0] === null || job.ArgumentsArray[0] === "null" ? "All" : (_d = tenants.find(function (t) { return t.GUID === job.ArgumentsArray[0].replace(/"/g, ""); })) === null || _d === void 0 ? void 0 : _d.DisplayName;
        }
        if (job.InvocationDataObject.Method === "DailyPolicyEnforcement") {
            TenantName = job.ArgumentsArray[0];
        }
        if (job.InvocationDataObject.Method === "HangfireDailyPolicyEnforcement") {
            TenantName = job.ArgumentsArray[1];
        }
        if (job.InvocationDataObject.Method === "HangfirePolicyEnforcementOnSpecificWorkspaces") {
            TenantName = job.ArgumentsArray[1];
        }
        if (job.InvocationDataObject.Method === "HangfireNightlyDataDeletionPolicy") {
            TenantName = "All";
        }
        if (job.InvocationDataObject.Method === "FixQueuedJobsForServersNotRunning") {
            TenantName = "All";
        }
        if (job.InvocationDataObject.Method === "HangfireArchivalProcessQueueJobSpecificTenant") {
            TenantName = job.ArgumentsArray[0] === null || job.ArgumentsArray[0] === "null" ? "All" : (_e = tenants.find(function (t) { return t.GUID === job.ArgumentsArray[0].replace(/"/g, ""); })) === null || _e === void 0 ? void 0 : _e.DisplayName;
        }
        if (job.InvocationDataObject.Method === "HangfireArchivalProcessQueueJobAllTenants") {
            TenantName = "All";
        }
        if (job.InvocationDataObject.Method === "HangfireStartJob") {
            TenantName = job.ArgumentsArray[1];
        }
        if (job.InvocationDataObject.Method === "HangfireProcessRequestsQueue") {
            TenantName = "All";
        }
        if (job.InvocationDataObject.Method === "RefreshAppSecrets") {
            TenantName = "All";
        }
        if (job.InvocationDataObject.Method === "RunScriptRunnerAutomatic") {
            TenantName = "All";
        }
        if (job.InvocationDataObject.Method === "RunTenantDbStartup") {
            TenantName = "All";
        }
        if (job.InvocationDataObject.Method === "MaintainTenantWebhooks") {
            TenantName = "All";
        }
        if (job.InvocationDataObject.Method === "") {
            TenantName = job.ArgumentsArray[0];
        }
        if (job.InvocationDataObject.Method === "") {
            TenantName = job.ArgumentsArray[0];
        }
        if (job.InvocationDataObject.Method === "CreateTestTeams") {
            TenantName = job.ArgumentsArray[0] === null || job.ArgumentsArray[0] === "null" ? "All" : (_f = tenants.find(function (t) { return t.GUID === job.ArgumentsArray[0].replace(/"/g, ""); })) === null || _f === void 0 ? void 0 : _f.DisplayName;
        }
        if (job.InvocationDataObject.Method === "HangfireProcessAssignPoliciesJob") {
            TenantName = job.ArgumentsArray[1] === null || job.ArgumentsArray[1] === "null" ? "All" : (_g = tenants.find(function (t) { return t.GUID === job.ArgumentsArray[1].replace(/"/g, ""); })) === null || _g === void 0 ? void 0 : _g.DisplayName;
        }
        return TenantName === undefined || TenantName === null || TenantName === "" ? "~~|" + job.InvocationDataObject.Method : TenantName.replace(/"/g, "");
    };
    var GetJobsWithFilters = function () {
        return jobs === null || jobs === void 0 ? void 0 : jobs.filter(function (job) {
            var returnItem = true;
            if (selectedJobType !== null) {
                if (job.InvocationDataObject.Method !== selectedJobType) {
                    returnItem = false;
                }
            }
            if (selectedStatus !== null) {
                if (job.StateName !== selectedStatus) {
                    returnItem = false;
                }
            }
            return returnItem;
        });
    };
    var GetDurration = function (job) {
        function roundToTwo(num) {
            //@ts-ignore
            return +(Math.round(num + "e+2") + "e-2");
        }
        function secondsToString(seconds) {
            var numyears = Math.floor(seconds / 31536000);
            var numdays = Math.floor((seconds % 31536000) / 86400);
            var numhours = Math.floor(((seconds % 31536000) % 86400) / 3600);
            var numminutes = Math.floor((((seconds % 31536000) % 86400) % 3600) / 60);
            var numseconds = roundToTwo((((seconds % 31536000) % 86400) % 3600) % 60);
            var numYearsString = numyears !== 0 ? numyears : "";
            var numDaysString = numdays !== 0 ? numdays + "days " : "";
            var numHoursString = numhours !== 0 ? React.createElement("span", { style: { color: "#F46036" } },
                numhours,
                "h ") : "";
            var numMinutesString = numminutes !== 0 ? React.createElement("span", { style: { color: "#367ca5" } },
                numminutes,
                "m ") : "";
            var numSecondsString = numseconds !== 0 ? React.createElement("span", { style: { color: "#1B998B" } },
                numseconds,
                "s ") : "";
            return (React.createElement(React.Fragment, null,
                numYearsString,
                numDaysString,
                numHoursString,
                numMinutesString,
                numSecondsString));
        }
        var startTime = job.StateProcessing !== null ? job.StateProcessing : job.CreatedAt;
        var endTime = job.StateSucceeded !== null ? job.StateSucceeded : job.StateFailed;
        if (endTime === null) {
            return "--";
        }
        var duration = moment.duration(moment(endTime).diff(moment(startTime)));
        var hours = secondsToString(duration.asSeconds());
        return hours;
        // return moment.utc(startTime).from(moment.utc(endTime));
    };
    var iFrameOnLoad = function () {
        try {
            if (iframeRef !== null && iframeRef.current !== null && iframeRef.current.contentDocument !== null) {
                var new_style_element = iframeRef.current.contentDocument.createElement("style");
                new_style_element.textContent =
                    "body { padding-top:30px; } #footer {display:none;} .navbar { display: none !important; }  .container > .row > .col-md-3 { display:none !important; } .container > .row > .col-md-9 { width:100% !important; }";
                iframeRef.current.contentDocument.head.appendChild(new_style_element);
            }
        }
        catch (_a) { }
    };
    var openHangfire = function (job) {
        setSelectedJobId(job.Id);
        if (window != null) {
            var url = "/hangfire-" + selectedRegion + "/jobs/details/" + job.Id;
            window.open(url, "_blank").focus();
        }
        // setModalOpen(true);
        // setTimeout(() => {
        //    iFrameOnLoad();
        // }, 100);
        // setTimeout(() => {
        //    iFrameOnLoad();
        // }, 300);
    };
    React.useEffect(function () {
        var timerID; //= setInterval(timer, 5000); //every 5 seconds
        timerID = setInterval(function () {
            getJobs(100, true);
        }, 10000);
        return function cleanup() {
            //When we leave component we stop the timer
            if (timerID !== null) {
                clearInterval(timerID);
            }
        };
    }, [selectedRegion, selectedTenant, selectedJobType, jobs]);
    // React.useEffect(() => {
    //    setSelectedTenant(null);
    //    setSelectedJobType(null);
    //    setSelectedStatus(null);
    // }, [selectedRegion]);
    return (React.createElement("div", { className: "orchestry-admin-job-browser fadein-500ms" },
        React.createElement(PageHeader.SetRightContent, null,
            React.createElement("div", { className: "refresh-button" }, loadingData === true ? React.createElement(SkeletonLoaderFakeBox, { bodyHeight: 20, bodyWidth: 100 }) : React.createElement(SubtitleButton, { color: "orange", title: "Refresh", size: "sm", onClick: function () { return getJobs(); } }))),
        React.createElement(Portlet, { className: "portlet-filters" },
            React.createElement(PortletBody, null,
                React.createElement(Row, null,
                    React.createElement(Col, { md: 2 },
                        React.createElement(Form.Group, { className: "form-group" },
                            React.createElement(Form.Label, null, "Region"),
                            activeRegions === undefined || activeRegions.length === 0 ? (React.createElement(SkeletonLoaderFakeBox, null)) : (React.createElement(FormDropdownSelect, { list: activeRegions, value: selectedRegion, onChange: function (value) {
                                    setSelectedTenant(null);
                                    setSelectedJobType(null);
                                    setSelectedStatus(null);
                                    setSelectedRegion(value);
                                } })))),
                    React.createElement(Col, null,
                        React.createElement(Form.Group, { className: "form-group" },
                            React.createElement(Form.Label, null, "Select Tenant"),
                            tenants.length === 0 ? (React.createElement(SkeletonLoaderFakeBox, { bodyHeight: 38 })) : (React.createElement(FormDropdownSelect, { enableSearch: true, value: selectedTenant, list: __spreadArray([
                                    {
                                        id: "0",
                                        title: "All",
                                        value: null
                                    }
                                ], tenants
                                    .sort(function (a, b) {
                                    if (a !== undefined && b !== undefined && a.DisplayName !== undefined && b.DisplayName !== undefined && a.DisplayName !== null && b.DisplayName !== null) {
                                        if (a.DisplayName < b.DisplayName) {
                                            return -1;
                                        }
                                        if (a.DisplayName > b.DisplayName) {
                                            return 1;
                                        }
                                    }
                                    return 0;
                                })
                                    .filter(function (t) { return t.DisplayName !== undefined && t.DisplayName !== "" && t.Region === selectedRegion; })
                                    .map(function (t) { return ({
                                    id: t.GUID !== undefined ? t.GUID : "",
                                    value: t.GUID !== undefined ? t.GUID : "",
                                    title: t.DisplayName !== undefined ? t.DisplayName : ""
                                }); }), true), onChange: function (tenantGuid) {
                                    setSelectedTenant(tenantGuid === undefined || (tenantGuid !== undefined && tenantGuid === null) ? null : tenantGuid);
                                } })))),
                    React.createElement(Col, null,
                        React.createElement(Form.Group, { className: "form-group" },
                            React.createElement(Form.Label, null, "Job Type"),
                            tenants.length === 0 ? (React.createElement(SkeletonLoaderFakeBox, { bodyHeight: 38 })) : (React.createElement(FormDropdownSelect, { enableSearch: true, value: selectedJobType, list: __spreadArray([
                                    {
                                        id: "0",
                                        title: "All",
                                        value: null
                                    }
                                ], GetJobTypes(), true), onChange: function (value) {
                                    setSelectedJobType(value);
                                } })))),
                    React.createElement(Col, { md: 2 },
                        React.createElement(Form.Group, { className: "form-group" },
                            React.createElement(Form.Label, null, "Status"),
                            tenants.length === 0 ? (React.createElement(SkeletonLoaderFakeBox, { bodyHeight: 38 })) : (React.createElement(FormDropdownSelect, { value: selectedStatus, list: [
                                    {
                                        id: "0",
                                        title: "All",
                                        value: null
                                    },
                                    {
                                        id: "1",
                                        title: "Succeeded",
                                        value: "Succeeded"
                                    },
                                    {
                                        id: "2",
                                        title: "Deleted",
                                        value: "Deleted"
                                    },
                                    {
                                        id: "3",
                                        title: "Processing",
                                        value: "Processing"
                                    },
                                    {
                                        id: "4",
                                        title: "Failed",
                                        value: "Failed"
                                    }
                                ], onChange: function (value) {
                                    setSelectedStatus(value);
                                } })))),
                    React.createElement(Col, { md: 1 },
                        React.createElement(Form.Group, { className: "form-group" },
                            React.createElement(Form.Label, null, "# Results"),
                            React.createElement(FormDropdownSelect, { value: topQuery, list: [
                                    {
                                        id: "0",
                                        title: "200",
                                        value: 200
                                    },
                                    {
                                        id: "1",
                                        title: "500",
                                        value: 500
                                    },
                                    {
                                        id: "2",
                                        title: "1000",
                                        value: 1000
                                    } //,
                                    // {
                                    //    id: "2",
                                    //    title: "5000",
                                    //    value: 5000
                                    // }
                                    // },
                                    // {
                                    //    id: "3",
                                    //    title: "Processing",
                                    //    value: 8000
                                    // }
                                ], onChange: function (value) {
                                    setTopQuery(value);
                                } })))))),
        React.createElement(Portlet, null,
            React.createElement(PortletHeader, null,
                React.createElement("div", { className: "job-header" },
                    React.createElement("div", { className: "job-tenant" }, "Tenant"),
                    React.createElement("div", { className: "job-name" }, "Job Name"),
                    React.createElement("div", { className: "job-status" }, "Status"),
                    React.createElement("div", { className: "job-time-durration" }, "Duration"),
                    React.createElement("div", { className: "job-time-ago" }, "Time Ago"),
                    React.createElement("div", { className: "job-time-date" }, "Date"))),
            React.createElement("div", { className: "scrollbar", style: { maxHeight: scollAreaHeight + "px", overflow: "auto" } },
                React.createElement("div", { className: "job-list" + (loadingData === true && jobs === null ? " loading-data" : "") }, (loadingData === true && jobs === null) || (loadingData === false && jobs === null) ? (React.createElement("div", { style: { margin: "20px 20px 0 20px" } },
                    React.createElement(SkeletonFakeTableRow, { repeatCols: 5 },
                        React.createElement(SkeletonFakeTableCol, null,
                            React.createElement(SkeletonLoaderFakeBox, { bodyHeight: 30 }))))) : (React.createElement("div", { className: "jobs" }, (_a = GetJobsWithFilters()) === null || _a === void 0 ? void 0 :
                    _a.map(function (job) { return (React.createElement("div", { className: "job-item", onClick: function () { return openHangfire(job); } },
                        React.createElement("div", { className: "job-wrapper" },
                            React.createElement("div", { className: "job-tenant" }, GetJobTenantName(job)),
                            React.createElement("div", { className: "job-name" }, GetJobName(job)),
                            React.createElement("div", { className: "job-status" }, job.StateName),
                            React.createElement("div", { className: "job-time-durration" }, GetDurration(job)),
                            React.createElement("div", { className: "job-time-ago" }, moment.utc(job.CreatedAt).fromNow(true)),
                            React.createElement("div", { className: "job-time-date" }, moment
                                .utc(job.CreatedAt)
                                .local()
                                .format("MMM DD, yyyy hh:mm a"))))); }),
                    ((_b = GetJobsWithFilters()) === null || _b === void 0 ? void 0 : _b.length) === 0 ? (React.createElement("div", { style: { marginTop: "40px" } },
                        React.createElement(NoResults, { mainText: "No jobs found", faIcon: "user-bounty-hunter" }))) : null))))),
        React.createElement(OrchModal, { size: "xl", showModalOverride: modalOpen, showModalToggleOverride: function (value) { return setModalOpen(value); }, modalClassName: "orchestry-admin-job-browser-modal", showCloseButton: true, headerContents: React.createElement("div", { style: { fontSize: "16px", fontWeight: 600 } },
                React.createElement("i", { className: "fa fa-link", style: { marginRight: "10px" } }),
                " ",
                React.createElement("a", { href: "/hangfire-" + selectedRegion + "/jobs/details/" + selectJobId, target: "_blank" }, "Hangfire Job")) },
            React.createElement("iframe", { ref: iframeRef, onLoad: function () { return iFrameOnLoad(); }, src: selectJobId === null ? "/hangfire-" + selectedRegion + "/" : "/hangfire-" + selectedRegion + "/jobs/details/" + selectJobId, style: { height: scollAreaHeight + 100 + "px" } }))));
});
export default OrchestryAdminJobBrowser;
